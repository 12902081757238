import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Button, Card } from 'react-bootstrap'
import './style.scss'

const ReportCard = ({
  titleLineClamp = 2,
  titleFontSize = '1.5rem',
  location,
  data,
  category = null,
  onClick,
  isRelated
}) => {
  return (
    <Link to={location + data.slug} className="no-style">
      <Card onClick={onClick} className="round-card">
        <GatsbyImage
          image={getImage(data.coverImage)}
          alt={data.title}
          style={{
            objectFit: 'cover',
            backgroundPosition: 'center',
            height: 150
          }}
        />
        <Card.Body
          style={{ height: data.description ? 290 : 180 }}
          className={
            'd-flex flex-column justify-content-between  align-items-start'
          }
        >
          <div>
            <p className={'mb-1 card-sub-title'}>
              {category || data?.categories?.[0]?.name?.toUpperCase()}
            </p>
            <Card.Title
              style={{ fontSize: titleFontSize }}
              className={`vertical-title-box-${titleLineClamp}`}
            >
              {data.title}
            </Card.Title>
            {data.description && (
              <Card.Text
                style={{ fontSize: '1rem' }}
                className={'text-black-50 vertical-text-box'}
              >
                {data.description}
              </Card.Text>
            )}
          </div>
          {isRelated ? (
            <Link
              to={location + data.slug}
              className="align-self-end"
              style={{ fontSize: '0.9rem' }}
            >
              Read More
            </Link>
          ) : (
            // if report card can be download directly change this footer
            // actions
            <div className="d-flex justify-content-between w-100">
              <Button variant="light" className={'px-2 py-1 rounded'}>
                <Link to={location + data.slug} className="no-style">
                  Details
                </Link>
              </Button>
              <Button
                variant="primary"
                className={'text-white px-2 py-1 rounded get-started'}
                href="https://app.uask.io"
                target="_blank"
              >
                Get Started
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </Link>
  )
}

export default ReportCard
