import BaseContainer from '@components/base-container'
import MarketResearchCard from '@components/report-card'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Breadcrumb, Button, Col, Container, Row } from 'react-bootstrap'
import './style.scss'

const colorSet = {
  bgColor: '#F9F9F9',
  irregular: '#6A778E',
  prominent: '#1E2D4F'
}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <h5 className="paragraph">{children}</h5>
    ),
    [BLOCKS.HEADING_3]: (node, children) => <h3 className="my-4">{children}</h3>
  }
}

const MarketResearchSinglePage = props => {
  const { title, description, content, pageImage, categories, id, coverImage } =
    props.pageContext.node

  const relatedCards = props.pageContext.related
    .filter(node => node.id !== id)
    .slice(0, 4)

  return (
    <BaseContainer>
      <section style={{ backgroundColor: colorSet.bgColor }} className="py-5">
        <Container className="pt-lg-5 pt-4">
          <Row>
            <Col lg={4} className="half-pill-img-wrapper">
              <div className="half-pill-img">
                <GatsbyImage
                  image={getImage(coverImage)}
                  alt={title}
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    backgroundPosition: 'center'
                  }}
                />
              </div>
            </Col>

            <Col lg={8} md={12}>
              <Breadcrumb className="mb-1 px-2">
                <Breadcrumb.Item>
                  <Link to="/market-research">Market Research</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{title}</Breadcrumb.Item>
              </Breadcrumb>

              <Row className="bg-white float-left-row">
                <Col lg={10} md={12}>
                  <h6 className={'mb-3 fw-light category-text'}>
                    {categories?.[0]?.name?.toUpperCase()}
                  </h6>
                  <h1 className="mb-3" style={{ color: colorSet.prominent }}>
                    <strong>{title}</strong>
                  </h1>
                  <h5 className="mb-0 paragraph">{description}</h5>
                  <div className="d-lg-none d-flex justify-content-center mt-3 ">
                    <GatsbyImage
                      image={getImage(coverImage)}
                      alt={title}
                      style={{
                        width: '100%',
                        height: 300,
                        objectFit: 'contain',
                        backgroundPosition: 'center'
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="document-row">
                <Col lg={9} md={12}>
                  {documentToReactComponents(JSON.parse(content.raw), options)}
                </Col>
                <div>
                  <Button
                    className="text-white mt-3"
                    href="https://app.uask.io"
                    target="_blank"
                  >
                    Get Started
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={{ span: 12 }}>
              <h5>Related Market Research</h5>
            </Col>
            {relatedCards.map(node => (
              <Col xl={3} lg={4} md={6} sm={12} xs={12} className={'py-2'}>
                <MarketResearchCard
                  key={node.id}
                  location="/market-research/"
                  data={node}
                  onClick={() => {}}
                  isRelated
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </BaseContainer>
  )
}
export default MarketResearchSinglePage
